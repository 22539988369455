import React, {useContext, useEffect, useState} from "react"
import Layout from "./layout";
import {Link, navigate} from "gatsby";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserContext} from "../context/UserContext";

const MyAccountLayout = ({children}) => {
  const {user, loading} = useContext(UserContext);
  const [groups, setGroups] = useState([])

  useEffect(() => {
    if (user) {
      setGroups(user.idToken.payload['cognito:groups'] || [])
    } else {
      setGroups([])
    }
  }, [user])

  const userIsInGroup = group => groups.includes(group);

  const linkStyle = 'font-title text-gray-700 text-xl uppercase no-underline tracking-wide py-2 px-4';

  const L = ({to, label, partiallyActive = false}) => <Link to={to + '/'} activeStyle={{color: 'black'}}
                                                            className={linkStyle}
                                                            partiallyActive={partiallyActive}>{label}</Link>
  if (loading) {
    return <FontAwesomeIcon className='text-gray-800 my-2' icon={faSpinner} spin={true} size="2x"/>
  }
  if (!user) {
    navigate('/login')
  }
  return (
    <Layout>
      <div className='bg-gray-100 flex flex-wrap justify-center mb-4 border-b border-grey py-4'>
        {!userIsInGroup('Partners') && <L to='/myaccount/mylookbook' label='My Lookbook' partiallyActive={true}/>}
        {!userIsInGroup('Partners') && <L to='/myaccount/stylereport' label='My Style Report'/>}
        {!userIsInGroup('Partners') && <L to='/myaccount/wishlist' label='Wishlist'/>}
        {!userIsInGroup('Partners') && <L to='/myaccount/membership' label='Membership'/>}
        {userIsInGroup('Stylists') && <L to='/myaccount/stylistprofile' label='Stylist Profile'/>}
        {userIsInGroup('Partners') && <L to='/myaccount/discount' label='Discount code'/>}
        {userIsInGroup('Partners') && <L to='/myaccount/partnerprofile' label='Account details'/>}
        <L to='/logout' label='Logout'/>
      </div>
      {children}
    </Layout>
  );
}


export default MyAccountLayout
