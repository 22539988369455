import React from "react";
import gql from "graphql-tag";
import {Query} from "react-apollo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import SEO from "../../components/seo";
import {client} from '../../apollo/client';
import MyAccountLayout from "../../components/myAccountLayout";

const DISCOUNT = gql`
    query Discount{
        discount{
            value
        }
    }
`

const DiscountPage = () => {
  return <MyAccountLayout>
    <div>
      <SEO title="Partner discount code" keywords={[`about us`, `fashion`]}/>
      <Query
        client={client}
        query={DISCOUNT}>{({loading, error, data}) => {
        if (loading) {
          return <div className="flex justify-center w-full my-16">
            <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/>
          </div>
        }
        if (error) {
          return <div className="my-8 font-sans w-full text-center text-red-700">Something went wrong, please try again
            later</div>
        }
        return <div className='flex flex-col justify-center items-center mb-16'>
          <h1 className='font-title font-bold text-4xl uppercase mt-8 mb-2 text-center tracking-wider'>Your discount
            code</h1>
          <div className="font-mono text-xl my-8">{data.discount.value}</div>
        </div>
      }}</Query>
    </div>

  </MyAccountLayout>
}

export default DiscountPage